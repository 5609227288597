exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sitepolicy-tsx": () => import("./../../../src/pages/sitepolicy.tsx" /* webpackChunkName: "component---src-pages-sitepolicy-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-service-index-tsx": () => import("./../../../src/templates/service/index.tsx" /* webpackChunkName: "component---src-templates-service-index-tsx" */)
}

